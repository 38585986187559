import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Link from "react-scroll/modules/components/Link";

import "./Navbar.css";
import logo from "../../assets/logo.webp";
import supportIcon from "../../assets/support-icon.png";

function NavbarComponent() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  });

  return (
    <Navbar
      className={scroll ? "navbar-main py-3 scrolling" : "navbar-main py-3"}
      variant="dark"
      collapseOnSelect
      expand="lg"
      id="navbar"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            alt="Oncarea"
            src={String(logo)}
            height="30"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to={"home"}
              hashSpy={true}
              spy={true}
              offset={-100}
              className="nav-item mx-2 px-3 py-1"
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"problem"}
              hashSpy={true}
              spy={true}
              offset={-100}
              className="nav-item mx-2 px-3 py-1"
            >
              Problem
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"solution"}
              hashSpy={true}
              spy={true}
              offset={-230}
              className="nav-item mx-2 px-3 py-1"
            >
              Solution
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"meet-oncai"}
              hashSpy={true}
              spy={true}
              offset={-230}
              className="nav-item mx-2 px-3 py-1"
            >
              Meet ONCAI
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"who-we-are"}
              hashSpy={true}
              spy={true}
              offset={-230}
              className="nav-item mx-2 px-3 py-1"
            >
              Who We Are
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"support"}
              hashSpy={true}
              spy={true}
              offset={-230}
              className="nav-item mx-2 py-1"
            >
              <img
                alt="support"
                src={String(supportIcon)}
                height="30"
                className="d-inline-block align-top"
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
