import { Col, Row } from "react-bootstrap";

import "./StatCard.css";

function StatCard(props) {
  return (
    <>
      <div className="statcard-container d-flex flex-md-column flex-lg-row">
        <Col className="statcard-text col-9 col-sm-12 col-lg-9 pe-4">
          {props.text}
        </Col>
        <Col className="statcard-icon col-3 col-sm-12 col-lg-3 ps-2">
          <img src={props.icon} alt="" />
        </Col>
      </div>
    </>
  );
}

export default StatCard;
