import { useRef, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Element } from "react-scroll/modules";
import HeroComponent from "../../components/Hero/Hero";
import StatCard from "../../components/StatCard/StatCard";
import InfoCard from "../../components/InfoCard/InfoCard";
import AnimatedSteps from "../../components/AnimatedSteps/AnimatedSteps";
import TeamComponent from "../../components/Team/Team";

import "@fontsource/mulish";
import "@fontsource/catamaran";
import "./Home.css";

import IndiaCancerIllustration from "../../assets/india-cancer-illustration.webp";
import IcoOneThird from "../../assets/stats-icons/ico-one-third.png";
import IcoTobacco from "../../assets/stats-icons/ico-tobacco.png";
import IcoHeartbeat from "../../assets/stats-icons/ico-heartbeat.png";
import IcoSearch from "../../assets/stats-icons/ico-search.png";
import IcoClinical from "../../assets/stats-icons/ico-clinical.png";
import Ico40 from "../../assets/stats-icons/ico-40.png";
import OncaiIcon from "../../assets/oncai.webp";
import VideoMP4 from "../../assets/video.mp4";
import VideoWEBM from "../../assets/video.webm";
import SupportedLogo1 from "../../assets/supported-logo-1.webp";
import SupportedLogo2 from "../../assets/supported-logo-2.webp";
import SupportedLogo3 from "../../assets/supported-logo-3.webp";

function Home() {
  const videoComponent = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const handleVideo = function () {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      videoComponent.current.pause();
    } else {
      videoComponent.current.play();
    }
  };

  return (
    <section>
      <HeroComponent />
      <Element name={"problem"}>
        <Container>
          <Row className="my-5">
            <div className="stats-column col-md-12 col-lg-4">
              <h2 id="stats-header-1" className="mt-2 mb-4">
                The Immeasurable Pain
              </h2>
              <div className="d-flex flex-column flex-sm-row flex-lg-column justify-content-start">
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        <span class="highlight">1/3 </span>
                        of world’s Oral Cancer prevalence is in{" "}
                        <span class="highlight">India</span>
                      </>
                    }
                    icon={IcoOneThird}
                  ></StatCard>
                </div>
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        Attributable risk due to tobacco and alcohol consumption
                        is <span class="highlight"> {">"} 80%</span>{" "}
                      </>
                    }
                    icon={IcoTobacco}
                  ></StatCard>
                </div>
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        Often occurs in people{" "}
                        <span class="highlight">over 40 with twice</span> as
                        much in <span class="highlight">males</span>
                      </>
                    }
                    icon={Ico40}
                  ></StatCard>
                </div>
              </div>
            </div>

            <div className="col-lg-4" id="india-illustration">
              <img
                src={IndiaCancerIllustration}
                alt="India cancer illustration"
              />
            </div>

            <div className="stats-column col-md-12 col-lg-4">
              <h2 id="stats-header-2" className="mt-2 mb-4">
                A Glimmer of Hope
              </h2>
              <div className="d-flex flex-column flex-sm-row flex-lg-column justify-content-end">
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        Solvable by{" "}
                        <span class="highlight">early detection</span>
                      </>
                    }
                    icon={IcoSearch}
                  ></StatCard>
                </div>
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        <span class="highlight">Early detection</span> increases
                        survival rate to{" "}
                        <span class="highlight">50% to 90%</span>
                      </>
                    }
                    icon={IcoHeartbeat}
                  ></StatCard>
                </div>
                <div className="statcard-wrapper me-3 me-lg-0 my-2 my-sm-5">
                  <StatCard
                    text={
                      <>
                        Long and detectable preclinical phase makes it an ideal
                        use-case for{" "}
                        <span class="highlight">Deep Learning</span>
                      </>
                    }
                    icon={IcoClinical}
                  ></StatCard>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Element>
      <Element name={"solution"}>
        <InfoCard
          image={OncaiIcon}
          header={
            <>
              <h2>
                Meet <b>ONCAI</b>
              </h2>
              <p>- your companion, always at your service!</p>
            </>
          }
          brief={
            <>
              <p>
                Oncai knows how an oral cancer looks like. All it needs is a
                photo from a SMARTPHONE! No need to wait and let your doubt,
                delay your recovery.
              </p>
              <p>
                <strong>
                  Not only that! Oncai will schedule a call for you with an
                  expert, automatically, and for free!
                </strong>
              </p>
            </>
          }
          topics={[
            {
              header: "Does Oncai offer more?",
              detail:
                "Yes, after the first call, our experts will give you referral to the best doctors in the country based on your location. Oncai will assist you in all the logistics of setting up the appointment and logging your medicines and helping you out at each and every step of the way!",
            },
            {
              header: "I need more!",
              detail:
                "We are working hard to bring together a complete suite of cancer care for you. Let Oncai help you in getting the right diet, the right oral hygiene products, the right mental health support and the right way to finance your battle!",
            },
          ]}
        />
      </Element>
      <Element name={"meet-oncai"}>
        <h2 className="oncai-header" id="oncai-animation-header">
          <b>ONCAI</b> - How To
        </h2>
        <AnimatedSteps />
        <h2 className="oncai-header mb-0">
          <b>ONCAI</b> - Behind The Scenes
        </h2>
        <div className="video-container">
          <video
            id="video-component"
            class="container"
            preload="metadata"
            ref={videoComponent}
            autoPlay
            loop
            muted
            playsInline
            onClick={() => handleVideo()}
          >
            <source src={VideoWEBM} id="video-source" type="video/webm" />
            <source src={VideoMP4} id="video-source" type="video/mp4" />
          </video>
          <Container>
            <div
              id="video-play-button"
              className={isPlaying ? "hidden" : "shown"}
              onClick={() => handleVideo()}
            >
              <i className="fa fa-play" />
            </div>
          </Container>
        </div>
      </Element>
      <Element name={"who-we-are"}>
        <section id="supported-section">
          <h2 className="regular-header">SUPPORTED BY</h2>
          <Container>
            <Row className="align-items-center">
              <Col className="col-12 col-md-5 col-lg-4">
                <img
                  src={SupportedLogo1}
                  alt="Supported by IIT Roorkee"
                  height={100}
                  className="my-3 supported-logo"
                />
              </Col>
              <Col className="col-12 col-md-3 col-lg-4">
                <img
                  src={SupportedLogo3}
                  alt="Supported by ihub Divyasampark"
                  height={150}
                  className="my-3 supported-logo"
                />
              </Col>
              <Col className="col-12 col-md-4 col-lg-4">
                <img
                  src={SupportedLogo2}
                  alt="Supported by Department of Science and Techonology, Government of India"
                  height={100}
                  className="my-3 supported-logo"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <TeamComponent />
      </Element>
    </section>
  );
}

export default Home;
