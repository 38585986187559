import { BrowserRouter } from "react-router-dom";
import NavbarComponent from "./components/Navbar/Navbar";
import FooterComponent from "./components/Footer/Footer";
import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <BrowserRouter>{routes}</BrowserRouter>
      <FooterComponent />
    </div>
  );
}

export default App;
