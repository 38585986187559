import Container from "react-bootstrap/Container";
import "./Team.css";

const team = require("../../assets/doc-1-1.webp");

function TeamComponent() {
  const teamMembers = [
    {
      fName: "Anmol",
      lName: "Gupta",
      designation: "Co-founder & CEO @ CogXR Labs",
      quali: "PhD, IIT Roorke & University of Groningen,",
      quali1: "M.Tech, NIT Hamirpur | B.Tech NIT Uttarakhand",
      teamem: require("../../assets/anmol.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/anmolscd/",
    },
    {
      fName: "Vishal",
      lName: "Pandey",
      designation: "Co-founder & CTO @ CogXR Labs",
      quali: "PhD, IIT Roorkee | Ex-SRF, DRDO",
      quali1: "M.Tech, BITS Pilani | B.Tech, DU",
      teamem: require("../../assets/visha_original.webp"),
      instagram: "",
      facebook: "",
      linkedin: " https://www.linkedin.com/in/vmyth/",
    },
    {
      fName: "Prof. Partha",
      lName: "Pratim Roy",
      designation: "Co-founder & Mentor @ CogXR Labs",
      quali:
        "Ass. Professor, CSE, IIT Roorke | Director Farmicon | Post Doc in France & Canada",
      quali1: "",
      teamem: require("../../assets/partha.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/dr-partha-pratim-roy-ab1144238/",
    },
    {
      fName: "Dr. Rajkumar",
      lName: "K Seenivasagam",
      designation: "Mentor",
      quali:
        "M.Ch, FRCS (Edin), FACS Professor and Head, Dept of Surgical Oncology",
      quali1: "",
      teamem: require("../../assets/rajkumar.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/rajkumar-k-seenivasagam/",
    },
    {
      fName: "Dr. Kinjal",
      lName: "Majumdar",
      designation: "Team Doctor",
      quali: "MBBS, MS, Mch (Head & Neck Surgical Oncologist) AIIMS Rishikesh",
      quali1: "",
      teamem: require("../../assets/kinjal.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/kinjal-shankar-majumdar-8570b486/",
    },
    {
      fName: "Dr. Shahab",
      lName: "Ali Usmani",
      designation: "Team Doctor",
      quali:
        "MBBS, MS (ENT), DNB, MRCS MCh (Head & Neck Surgical Oncologist) AIIMS Rishikesh",
      quali1: "",
      teamem: require("../../assets/shahab.webp"),
      instagram: "",
      facebook: "",
      linkedin: "",
    },
    {
      fName: "Dr. Abhinav",
      lName: "Thaduri",
      designation: "Team Doctor",
      quali:
        "MBBS, MS (ORL-HNS), MCH (Head & Neck Surgical Oncologist) AIIMS Rishikesh",
      quali1: "",
      teamem: require("../../assets/abhinav.webp"),
      instagram: "",
      facebook: "",
      linkedin: " https://www.linkedin.com/in/abhinav-thaduri-a79523120/",
    },
    {
      fName: "Dr. Achyuth",
      lName: "Panuganti",
      designation: "Team Doctor",
      quali:
        "MBBS, MS (ORL-HNS), MCH (Head & Neck Surgical Oncologist) AIIMS Rishikesh",
      quali1: "",
      teamem: require("../../assets/achyut.webp"),
      instagram: "",
      facebook: "",
      linkedin: " https://www.linkedin.com/in/dr-achyuth-panuganti-585306114/",
    },
  ];

  return (
    <>
      <section className="team text-white">
        <Container>
          <h2 className="heading-title text-uppercase font-weight-lighter mt-5 pt-5 text-white">
            Meet our team
          </h2>
        </Container>
        <Container className="card-list-container">
          <div className="card-list row">
            {teamMembers.map((member, index) => {
              return (
                <div
                  className="card align-items-center p-2 col-6 col-md-4 col-lg-3"
                  key={index}
                  id="card"
                >
                  <div id="circle">
                    <img
                      class="circle-image"
                      src={member.teamem}
                      alt="Team-members"
                    />
                  </div>
                  <div className="profile-info">
                    <p className="name">
                      {member.fName} {member.lName}
                    </p>
                    <p id="designation">{member.designation}</p>
                    <p className="quali">{member.quali}</p>
                    <p className="quali">{member.quali1}</p>
                  </div>
                  <div className="social-media-icons">
                    {/* <a href={member.facebook} className="social-links p-1" target="_blank">
                        <i className="fa-brands fa-facebook" id="facebook"></i>
                      </a>
                      <a href={member.instagram} className="social-links p-1" target="_blank">
                        <i className="fa-brands fa-instagram" id="instagram"></i>
                      </a> */}
                    <a
                      href={member.linkedin}
                      className="social-links p-1"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin" id="linkedin"></i>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>

        {/* <ScrollContainer className="card-list-container">
            <div className="card-list row justify-content-center">
              {teamMembers.map((member, index) => {
                return (
                  <div
                    className="card align-items-center col-3 col-mb-3 col-sm-3 col-lg-3 col-xl-3"
                    key={index}
                    id="card"
                  >
                    <div className="left-middle">
                      <h4 className="card-title">{member.fName}</h4>
                      <h2 className="card-subtitle">{member.lName}</h2>
                    </div>
  
                    <div className="des">
                      <h4 className="card-text text-start">About</h4>
                      <p className="card-text text-start">{member.about}</p>
                    </div>
  
                    <div className="social-media-icons">
                      <a href="/" className="social-links">
                        <i className="fa-brands fa-behance" id="behance"></i>
                      </a>
                      <a href="/" className="social-links">
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollContainer> */}
      </section>
    </>
  );
}

export default TeamComponent;
