import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Element from "react-scroll/modules/components/Element";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./Hero.css";

import BackgroundVideoMP4 from "../../assets/oncarea.mp4";
import BackgroundVideoWEBM from "../../assets/oncarea.webm";
import appstore from "../../assets/appstore.webp";
import playstore from "../../assets/playstore.webp";

function HeroComponent() {
  const bgVideo = useRef(null);
  useEffect(() => {
    if (bgVideo.readyState === 4) {
      bgVideo.current.play();
    }
  }, []);

  return (
    <Element name={"home"}>
      <div id="hero-container">
        <div id="video-wrapper">
          <video
            id="bg-video"
            preload="auto"
            ref={bgVideo}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={BackgroundVideoWEBM} type="video/webm"></source>
            <source src={BackgroundVideoMP4} type="video/mp4"></source>
          </video>
        </div>

        <Container id="hero-content">
          <div className="row flex-lg-row flex-md-column align-items-center g-1 py-5">
            <div className="col-lg-6">
              <div className="row">
                <h1
                  className="col-md-12 col-lg-6 col-xl-5 display-4 fw-bold text-start"
                  id="onco"
                >
                  Oncarea
                </h1>
                <div
                  id="store-icon"
                  className="col-md-12 col-lg-7 col-xl-6 ms-xl-4"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Coming Soon!</Tooltip>}
                    variant="light"
                  >
                    <img
                      id="appstore"
                      src={appstore}
                      alt="Download on the app store"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Coming Soon!</Tooltip>}
                    variant="light"
                  >
                    <img
                      id="playstore"
                      src={playstore}
                      alt="Download on the play store"
                    />
                  </OverlayTrigger>
                </div>{" "}
              </div>
              <p className="lead text-start lh-1 text-white my-2" id="tagline">
                Your companion through thick and thin
              </p>
              <p
                className="lead text-start lh-2 text-white font-weight-light py-2"
                id="des-text"
              >
                The journey through cancer is gruelling. Let us walk with you,
                hand-in-hand, and win over cancer.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Element>
  );
}

export default HeroComponent;
