import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollContainer from "react-indiana-drag-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./AnimatedSteps.css";
import step1 from "../../assets/animation/step-1.png";
import step2 from "../../assets/animation/step-2.png";
import step3 from "../../assets/animation/step-3.png";
import step4 from "../../assets/animation/step-4.png";
import step5 from "../../assets/animation/step-5.png";
import step1hover from "../../assets/animation/1-c.png";
import step2hover from "../../assets/animation/2-c.png";
import step3hover from "../../assets/animation/3-c.png";
import step4hover from "../../assets/animation/4-c.png";
import step5hover from "../../assets/animation/5-c.png";

gsap.registerPlugin(ScrollTrigger);

function AnimatedSteps() {
  const container = useRef();
  const mm = gsap.matchMedia();

  const firstImage = useRef(null);

  function hoverFirstImage() {
    firstImage.current.dispatchEvent(
      new MouseEvent("mouseover", {
        bubbles: true,
      })
    );
  }

  useEffect(() => {
    const tlOne = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "bottom+=1000rem bottom+=400rem",
        end: "top top-=1600rem",
        onEnter: hoverFirstImage(),
      },
    });

    const tlTwo = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "bottom+=1000rem bottom+=900rem",
        end: "top top-=1600rem",
      },
    });
    mm.add("(min-width: 1200px)", () => {
      tlOne.fromTo(".images", { xPercent: 10 }, { xPercent: 0 });
    });
    mm.add("(min-width: 300px)", () => {
      tlTwo.fromTo(".images", { xPercent: 10 }, { xPercent: 0 });
    });
  }, []);

  const handleScroll = function (direction) {
    if (direction === "right") {
      container.current.scrollBy({ left: 300, behavior: "smooth" });
    }
    if (direction === "left") {
      container.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        id="animated-steps-container"
        className="row"
        onKeyPress={(e) => {
          if (e.key === "ArrowLeft") {
            e.handleScroll("left");
          } else if (e.key === "ArrowRight") {
            e.handleScroll("right");
          }
        }}
      >
        <button
          onClick={() => handleScroll("left")}
          className="btn col-2 col-xl-1"
          id="step-control-left"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
        <div id="animated-steps" className="col-8 col-xl-10" ref={container}>
          <div className="images py-2">
            <div className="step-container">
              <img
                src={step1}
                className="step"
                id="step-1"
                alt=""
                srcset=""
                ref={firstImage}
              ></img>
              <img
                src={step1hover}
                className="step-hover"
                id="step1-hover"
                alt=""
              ></img>
            </div>

            <hr className="step-connector" id="connector-1" />

            <div className="step-container">
              <img src={step2} id="step-2" className="step" alt="" srcset="" />
              <img
                src={step2hover}
                className="step-hover"
                id="step2-hover"
                alt=""
              ></img>
            </div>

            <hr className="step-connector" id="connector-2" />
            <div className="step-container">
              <img src={step3} id="step-3" className="step" alt="" srcset="" />
              <img
                src={step3hover}
                className="step-hover"
                id="step3-hover"
                alt=""
              ></img>
            </div>
            <hr className="step-connector" id="connector-3" />
            <div className="step-container">
              <img src={step4} id="step-4" className="step" alt="" srcset="" />
              <img
                src={step4hover}
                className="step-hover"
                id="step4-hover"
                alt=""
              ></img>
            </div>
            <hr className="step-connector" id="connector-4" />
            <div className="step-container">
              <img src={step5} id="step-5" className="step" alt="" srcset="" />
              <img
                src={step5hover}
                className="step-hover"
                id="step5-hover"
                alt=""
              ></img>
            </div>
          </div>
        </div>
        <button
          onClick={() => handleScroll("right")}
          className="btn col-2 col-xl-1"
          id="step-control-right"
        >
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </>
  );
}

export default AnimatedSteps;
