import { Row, Col, Container } from "react-bootstrap";

import "./InfoCard.css";

function InfoCard(props) {
  return (
    <Container className="info-card-container">
      <Row className="info-card-header">{props.header}</Row>
      <Row className="info-card-brief my-4">{props.brief}</Row>
      <Row className="info-card-data mx-2">
        <Col className="col-12 col-md-7 col-lg-8 col-xl-9 info-card-text pe-md-5">
          {props.topics.map((topic) => {
            return (
              <Row className="info-card-topic">
                <h5 className="info-topic-header">
                  <strong>{topic.header}</strong>
                </h5>
                <p className="info-topic-detail py-3">{topic.detail}</p>
              </Row>
            );
          })}
        </Col>
        <Col className="col-12 col-md-5 col-lg-4 col-xl-3 info-card-image">
          <img src={props.image} alt=""></img>
        </Col>
      </Row>
    </Container>
  );
}

export default InfoCard;
